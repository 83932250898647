import { DrawerScreenProps } from "@react-navigation/drawer";
import { Link } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { useEffect } from "react";
import { FlatList, Text, View } from "react-native";
import { GlobalStyles, MARGIN_MEDIUM } from "../lib/styles";
import {
  formatClockTime,
  formatTime,
  getTimeToDistanceAtPace,
  getUserTimePerKilometer,
} from "../lib/time-functions";
import { formatHighlightStepTitle, formatPaceUserTitle } from "../lib/titles";
import { useHighlightSteps } from "../state/HighlightStepsState";
import { usePaceUsers } from "../state/PaceUsersState";
import { useRace } from "../state/RaceState";
import { HighlightStep, PaceUser } from "../types/common";

type Props = DrawerScreenProps<any>;

export function HighlightStepDetailScreen({ route, navigation }: Props) {
  const { paceUsers } = usePaceUsers();
  const { highlightSteps } = useHighlightSteps();
  const {
    race: { startTime },
  } = useRace();
  const highlight = highlightSteps.find((s) => s.id === route.params.id);

  useEffect(() => {
    if (highlight?.name) {
      navigation.setOptions({
        headerTitle: formatHighlightStepTitle(highlight),
      });
    }
  }, [highlight]);

  if (!highlight) {
    return;
  }

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      {!!highlight.notes && (
        <Text style={{ margin: MARGIN_MEDIUM }}>{highlight.notes}</Text>
      )}
      <FlatList
        data={paceUsers}
        ListHeaderComponent={HighlightStepHeader}
        renderItem={({ item: user }) => (
          <HighlightStepRow
            user={user}
            highlight={highlight}
            startTime={startTime}
          />
        )}
        keyExtractor={(item) => item.id}
        style={GlobalStyles.list}
        contentContainerStyle={GlobalStyles.listContainer}
      />
    </View>
  );
}

function HighlightStepHeader() {
  return (
    <View style={GlobalStyles.listItem}>
      <View style={{ flex: 1 }}>
        <Text style={GlobalStyles.textBold}>Runner</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={GlobalStyles.textBold}>Running time</Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text style={GlobalStyles.textBold}>Time at point</Text>
      </View>
    </View>
  );
}

type RowProps = {
  user: PaceUser;
  highlight: HighlightStep;
  startTime: number;
};
function HighlightStepRow({ user, highlight, startTime }: RowProps) {
  const kilometerTime = getUserTimePerKilometer(user);
  return (
    <View style={GlobalStyles.listItem}>
      <View style={{ flex: 1 }}>
        <Link to={`/runners/${user.id}`}>
          <Text>{formatPaceUserTitle(user)}</Text>
        </Link>
      </View>
      <View style={{ flex: 1 }}>
        <Text>
          {formatTime(
            getTimeToDistanceAtPace({
              kilometerTime,
              distance: highlight.distance,
            })
          )}
        </Text>
      </View>
      <View style={{ flex: 1 }}>
        <Text>
          {formatClockTime(
            startTime +
              getTimeToDistanceAtPace({
                kilometerTime,
                distance: highlight.distance,
              })
          )}
        </Text>
      </View>
    </View>
  );
}
