import { Link } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { Button, FlatList, Text, View } from "react-native";
import { AddHighlightStepForm } from "../components/AddHighlightStepForm";
import { GlobalStyles, MARGIN_SMALL } from "../lib/styles";
import { formatHighlightStepTitle } from "../lib/titles";
import { useHighlightSteps } from "../state/HighlightStepsState";

export function HighlightStepsScreen() {
  const { highlightSteps, addHighlightStep, removeHighlightStep } =
    useHighlightSteps();

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <AddHighlightStepForm onAddHighlightStep={addHighlightStep} />

      <FlatList
        data={highlightSteps}
        renderItem={({ item: step }) => (
          <View style={GlobalStyles.listItem}>
            <Link to={`/highlights/${step.id}`} style={{ flexShrink: 1 }}>
              <Text>{formatHighlightStepTitle(step)}</Text>
            </Link>
            {!!step.notes && (
              <Text
                style={[
                  GlobalStyles.textCenter,
                  { flex: 1, marginHorizontal: MARGIN_SMALL },
                ]}
              >
                {step.notes}
              </Text>
            )}
            {/* <Button title="Edit" /> */}
            <Button title="Remove" onPress={() => removeHighlightStep(step)} />
          </View>
        )}
        keyExtractor={(item) => item.id}
        style={GlobalStyles.list}
        contentContainerStyle={GlobalStyles.listContainer}
      />
    </View>
  );
}
