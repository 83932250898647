import AsyncStorage from "@react-native-async-storage/async-storage";
import { createContext, useContext, useEffect, useState } from "react";
import {
  HOUR,
  KUSTMARATHON_COURSE_POLYLINE,
  MARATHON,
  MINUTE,
  ROTTERDAM_COURSE_POLYLINE,
} from "../lib/constants";
import { parsePace } from "../lib/time-functions";
import { generateRandomId } from "../lib/uuid";
import { Race } from "../types/common";

const defaultValue: Race = {
  id: generateRandomId(),
  name: "Kustmarathon",
  distance: MARATHON,
  startTime: (parsePace("12:00") * HOUR) / MINUTE,
  coursePolyline: KUSTMARATHON_COURSE_POLYLINE,
};

const RaceContext = createContext({
  race: defaultValue,
  updateRace: (race: Race) =>
    console.error("updateRace default method", { race }),
});

export const useRace = () => useContext(RaceContext);

export function RaceContextProvider({ children }) {
  const STORAGE_KEY = "MARATHON::RACE";
  const [race, setRace] = useState(defaultValue);

  useEffect(() => {
    AsyncStorage.getItem(STORAGE_KEY).then((value) => {
      if (value) {
        setRace(JSON.parse(value));
      }
    });
  }, []);

  useEffect(() => {
    if (race !== defaultValue) {
      AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(race));
    }
  }, [race]);

  const updateRace = (updatedRace: Race) => {
    console.log("updateRace", updatedRace);
    setRace(updatedRace);
  };

  return (
    <RaceContext.Provider value={{ race, updateRace }}>
      {children}
    </RaceContext.Provider>
  );
}
