import { StatusBar } from "expo-status-bar";
import gpxParser from "gpxparser";
import { useRef, useState } from "react";
import { Button, TextInput, View } from "react-native";
import { FileUploader } from "../components/FileUploader";
import { MapView } from "../components/map/MapView";
import { encodePolyline } from "../lib/polyline";
import { Colors, GlobalStyles } from "../lib/styles";

const gpx = new gpxParser();

export function GpxScreen() {
  const nameRef = useRef<TextInput>();

  const [name, setName] = useState<string>();
  const [polyline, setPolyline] = useState<string>();

  const parseGPX = (gpxFile: string) => {
    gpx.tracks = [];
    gpx.parse(gpxFile);

    console.log(gpx);
    const track = gpx.tracks[0];
    if (!track) {
      return;
    }

    const newPolyline = encodePolyline(
      track.points.map((p) => ({ latitude: p.lat, longitude: p.lon }))
    );

    // Needs to be replaced for display
    // .replaceAll("\\", "\\\\");
    // console.log(newPolyline);

    setPolyline(newPolyline);
    setName(track.name);
  };

  const saveRoute = () => {
    console.log({ name, polyline });
    // TODO
  };

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      {/* TODO: Disable when there is no polyline */}
      <TextInput
        ref={nameRef}
        style={GlobalStyles.textInput}
        placeholder="Name"
        placeholderTextColor={Colors.GREY}
        value={name} // is this an anti-pattern?
        onChangeText={setName}
      />
      <FileUploader onFileRead={parseGPX} />
      {!!name && !!polyline && (
        <Button title={`${name} opslaan als route`} onPress={saveRoute} />
      )}

      {!!polyline && <MapView polyline={polyline} />}
    </View>
  );
}
