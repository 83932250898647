import AsyncStorage from "@react-native-async-storage/async-storage";
import { createContext, useContext, useEffect, useState } from "react";
import { HighlightStep } from "../types/common";

const defaultValue: HighlightStep[] = [];

const HighlightStepsContext = createContext({
  highlightSteps: defaultValue,
  addHighlightStep: (step: HighlightStep) =>
    console.error("addHighlightStep default method", { step }),
  removeHighlightStep: (step: HighlightStep) =>
    console.error("removeHighlightStep default method", { step }),
  updateHighlightSteps: (steps: HighlightStep[]) =>
    console.error("updateHighlightSteps default method", { steps }),
});

export const useHighlightSteps = () => useContext(HighlightStepsContext);

export function HighlightStepsContextProvider({ children }) {
  const STORAGE_KEY = "MARATHON::HIGHLIGHT_STEPS";
  const [highlightSteps, setHighlightSteps] = useState(defaultValue);

  const addHighlightStep = (step: HighlightStep) => {
    console.log("addHighlightStep", step);
    setHighlightSteps(
      [...highlightSteps, step].sort((a, b) => a.distance - b.distance)
    );
  };

  const removeHighlightStep = (step: HighlightStep) => {
    console.log("removeHighlightStep", step);
    setHighlightSteps(highlightSteps.filter((s) => s.id !== step.id));
  };

  const updateHighlightSteps = (steps: HighlightStep[]) => {
    console.log("updateHighlightSteps", steps);
    setHighlightSteps([...steps]);
  };

  useEffect(() => {
    AsyncStorage.getItem(STORAGE_KEY).then((value) => {
      if (value) {
        setHighlightSteps(JSON.parse(value));
      }
    });
  }, []);

  useEffect(() => {
    if (highlightSteps !== defaultValue) {
      AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(highlightSteps));
    }
  }, [highlightSteps]);

  return (
    <HighlightStepsContext.Provider
      value={{
        highlightSteps,
        addHighlightStep,
        removeHighlightStep,
        updateHighlightSteps,
      }}
    >
      {children}
    </HighlightStepsContext.Provider>
  );
}
