import { Text, View } from "react-native";
import { GlobalStyles } from "../lib/styles";
import {
  formatClockTime,
  formatTime,
  getTimeToDistanceAtPace,
} from "../lib/time-functions";

type Props = {
  kilometerTime: number;
  distance: number;
  startTime?: number;
};

export function PaceUserStep({ kilometerTime, distance, startTime }: Props) {
  if (!distance) {
    return;
  }

  const timeToStep = getTimeToDistanceAtPace({ kilometerTime, distance });

  return (
    <View style={GlobalStyles.columnRow}>
      <Text style={{ marginLeft: 8 }}>{formatTime(timeToStep)}</Text>
      {!!startTime && (
        <Text style={{ marginLeft: 8 }}>
          {formatClockTime(startTime + timeToStep)}
        </Text>
      )}
    </View>
  );
}
