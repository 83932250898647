import { PaceUser, UnitSystem } from "../types/common";
import { HOUR, KM, MILE_IN_KM, MINUTE, SECOND } from "./constants";

export function convertTimeToSeconds(input: {
  hours?: number;
  minutes?: number;
  seconds?: number;
}): number {
  const { hours = 0, minutes = 0, seconds = 0 } = input;
  return hours * HOUR + minutes * MINUTE + seconds * SECOND;
}

function prefixZero(input: number) {
  return input < 10 ? `0${input}` : `${input}`;
}

export function formatPace(
  secondsPerKilometer: number,
  unitSystem: UnitSystem = "metric"
) {
  const seconds =
    unitSystem === "imperial"
      ? secondsPerKilometer * MILE_IN_KM
      : secondsPerKilometer;

  return formatTime(seconds);
}

export function formatTime(seconds: number) {
  if (seconds > HOUR) {
    return `${Math.floor(seconds / HOUR)}:${prefixZero(
      Math.floor((seconds % HOUR) / MINUTE)
    )}:${prefixZero(Math.floor(seconds % MINUTE))}`;
  }

  return `${Math.floor(seconds / MINUTE)}:${prefixZero(
    Math.round(seconds % MINUTE)
  )}`;
}

export function formatClockTime(seconds: number) {
  return `${Math.floor(seconds / HOUR)}:${prefixZero(
    Math.floor((seconds % HOUR) / MINUTE)
  )}`;
}

export function parsePace(input: string) {
  const [minutes, seconds] = input.split(":").map((num) => parseInt(num));

  return minutes * MINUTE + seconds * SECOND;
}

export function parseTime(input: string) {
  const [hours, minutes, seconds] = input
    .split(":")
    .map((num) => parseInt(num));

  return hours * HOUR + minutes * MINUTE + seconds * SECOND;
}

export function getUserTimePerKilometer(user: PaceUser) {
  return user.pace.type === "kilometer-pace"
    ? user.pace.time
    : (user.pace.time / user.pace.distance) * KM;
}

export function getTimeToDistanceAtPace({
  kilometerTime,
  distance,
}: {
  kilometerTime: number;
  distance: number;
}) {
  return (kilometerTime * distance) / KM;
}
