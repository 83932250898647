import { createDrawerNavigator } from "@react-navigation/drawer";
import { HighlightStepsScreen } from "../screens/HighlightStepsScreen";
import { MapScreen } from "../screens/MapScreen";
import { OverviewScreen } from "../screens/OverviewScreen";
import { PaceUsersScreen } from "../screens/PaceUsersScreen";
import { GpxScreen } from "../screens/GPXScreen";

const Drawer = createDrawerNavigator();

export function DrawerNavigator() {
  return (
    <Drawer.Navigator initialRouteName="Overview">
      <Drawer.Screen
        name="Overview"
        component={OverviewScreen}
        options={{
          title: "Marathon supporter 😎⏱️",
          drawerLabel: "Overview",
        }}
      />
      <Drawer.Screen
        name="PaceUsers"
        component={PaceUsersScreen}
        options={{
          title: "Runners",
        }}
      />
      <Drawer.Screen
        name="HighlightSteps"
        component={HighlightStepsScreen}
        options={{
          title: "Highlights",
        }}
      />
      <Drawer.Screen
        name="Map"
        component={MapScreen}
        options={{
          title: "Course Map",
        }}
      />
      <Drawer.Screen
        name="GPX"
        component={GpxScreen}
        options={{
          title: "GPX",
        }}
      />
    </Drawer.Navigator>
  );
}
