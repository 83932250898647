import { useRef, useState } from "react";
import {
  Button,
  InteractionManager,
  Text,
  TextInput,
  View,
} from "react-native";
import { MARATHON } from "../lib/constants";
import { Colors, GlobalStyles } from "../lib/styles";
import { parsePace, parseTime } from "../lib/time-functions";
import { generateRandomId } from "../lib/uuid";
import { PaceUser } from "../types/common";

type Props = {
  onAddUser: (user: PaceUser) => void;
};

export function AddUserForm({ onAddUser }: Props) {
  const nameRef = useRef<TextInput>();
  const targetPaceRef = useRef<TextInput>();
  const targetTimeRef = useRef<TextInput>();

  const [name, setName] = useState<string>();
  const [targetPace, setTargetPace] = useState<number>();
  const [targetTime, setTargetTime] = useState<number>();

  const addPaceUser = () => {
    if (!name || (!targetPace && !targetTime)) {
      alert("Please fill in the details");
      return;
    }

    onAddUser({
      id: generateRandomId(),
      name,
      pace: targetTime
        ? { type: "race-time", distance: MARATHON, time: targetTime }
        : {
            type: "kilometer-pace",
            time: targetPace,
          },
    });

    InteractionManager.runAfterInteractions(() => {
      nameRef.current.focus();
      nameRef.current.clear();
      targetPaceRef.current.clear();
      targetTimeRef.current.clear();
      setName(undefined);
      setTargetPace(undefined);
      setTargetTime(undefined);
    });
  };

  const focusTargetPace = () => targetPaceRef.current.focus();

  return (
    <View style={GlobalStyles.form}>
      <TextInput
        ref={nameRef}
        style={GlobalStyles.textInput}
        placeholder="Name"
        placeholderTextColor={Colors.GREY}
        onChangeText={setName}
        onSubmitEditing={focusTargetPace}
      />
      <TextInput
        ref={targetPaceRef}
        style={GlobalStyles.textInput}
        placeholder="Target pace (X:XX)"
        placeholderTextColor={Colors.GREY}
        onChangeText={(text) => setTargetPace(parsePace(text))}
        keyboardType="numeric"
        onSubmitEditing={addPaceUser}
      />
      <Text>OR</Text>
      <TextInput
        ref={targetTimeRef}
        style={GlobalStyles.textInput}
        placeholder="Target time (X:XX:XX)"
        placeholderTextColor={Colors.GREY}
        onChangeText={(text) => setTargetTime(parseTime(text))}
        keyboardType="numeric"
        onSubmitEditing={addPaceUser}
      />
      <Button title="Add" onPress={addPaceUser} />
    </View>
  );
}
