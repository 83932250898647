import { StatusBar } from "expo-status-bar";
import { StyleSheet, View } from "react-native";
import { MapView, Marker } from "../components/map/MapView";
import {
  calculatePolylineLength,
  getLocationForDistanceOnRoute,
} from "../lib/polyline";
import { GlobalStyles } from "../lib/styles";
import { formatHighlightStepTitle } from "../lib/titles";
import { useHighlightSteps } from "../state/HighlightStepsState";
import { useRace } from "../state/RaceState";

export function MapScreen() {
  const { highlightSteps } = useHighlightSteps();
  const { race } = useRace();
  const highlightsWithCoordinates = highlightSteps.map((step) => {
    const coordinate = getLocationForDistanceOnRoute(
      race.coursePolyline,
      step.distance
    );

    return { ...step, coordinate };
  });

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <MapView
        style={StyleSheet.absoluteFillObject}
        polyline={race.coursePolyline}
      >
        <Marker
          key="start"
          coordinate={getLocationForDistanceOnRoute(race.coursePolyline, 0)}
          title="Start"
          pinColor="green"
        />
        {highlightsWithCoordinates.map((highlight) => (
          <Marker
            key={highlight.id}
            coordinate={highlight.coordinate}
            title={formatHighlightStepTitle(highlight)}
            pinColor="blue"
          />
        ))}
        <Marker
          key="finish"
          coordinate={getLocationForDistanceOnRoute(
            race.coursePolyline,
            calculatePolylineLength(race.coursePolyline)
          )}
          title="Finish"
          pinColor="red"
        />
      </MapView>
    </View>
  );
}
