import { View } from "react-native";
import { getSortedSteps } from "../lib/steps";
import { GlobalStyles } from "../lib/styles";
import { HighlightStep } from "../types/common";
import { LegendStep } from "./LegendStep";

type Props = {
  distance: number;
  extraSteps?: HighlightStep[];
};

export function LegendColumn({ distance, extraSteps }: Props) {
  const steps = getSortedSteps(distance, extraSteps);

  return (
    <View style={[GlobalStyles.column, { alignItems: "flex-end" }]}>
      {steps.map((step) => (
        <LegendStep key={`legend-${step.distance}`} step={step} />
      ))}
    </View>
  );
}
