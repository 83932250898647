import { HALF_MARATHON, KM, MARATHON } from "./constants";

export function formatDistance(distanceInMeters: number, unit = "km") {
  // TODO: imperial / meters / whatever

  if (distanceInMeters === MARATHON) {
    return "Marathon";
  } else if (distanceInMeters === HALF_MARATHON) {
    return "Half Marathon";
  }

  switch (unit) {
    case "km":
      return `${distanceInMeters / KM} ${unit}`; // TODO: Rounding
    default:
      return distanceInMeters;
  }
}
