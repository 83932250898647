import { StatusBar } from "expo-status-bar";
import { Text, View } from "react-native";
import { GlobalStyles } from "../lib/styles";

export function PrivacyScreen() {
  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <Text>
        Your data is only stored locally, and is therefore as safe as the device
        you are using this app on.
      </Text>
    </View>
  );
}
