// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import {
    collection,
    doc,
    getDoc,
    getFirestore,
    setDoc,
} from "firebase/firestore";
import { HighlightStep, PaceUser, Race } from "../types/common";
import { generateRandomId } from "./uuid";
// Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCu799vhKfU45vzf3TBIJo6OqRKl_YfkvQ",
  authDomain: "marathon-383010.firebaseapp.com",
  projectId: "marathon-383010",
  storageBucket: "marathon-383010.appspot.com",
  messagingSenderId: "201532346940",
  appId: "1:201532346940:web:c9060be112f9b97db8e939",
  measurementId: "G-8697EBJCN0",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const firestore = getFirestore(app);
const sharedRaceState = collection(firestore, "shared-race-state");

export const storeSharedRaceState = async ({
  race,
  paceUsers,
  highlightSteps,
}: {
  race: Race;
  paceUsers: PaceUser[];
  highlightSteps: HighlightStep[];
}) => {
  const raceId = generateRandomId();
  await setDoc(doc(sharedRaceState, raceId), {
    race,
    paceUsers,
    highlightSteps,
  });

  return raceId;
};

export const getSharedRaceState = async (raceId: string) => {
  return (await getDoc(doc(sharedRaceState, raceId))).data();
};
