import { createStackNavigator } from "@react-navigation/stack";
import { HighlightStepDetailScreen } from "../screens/HighlightStepDetailScreen";
import { PaceUserDetailScreen } from "../screens/PaceUserDetailScreen";
import { PrivacyScreen } from "../screens/PrivacyScreen";
import { ShareScreen } from "../screens/ShareScreen";
import { DrawerNavigator } from "./DrawerNavigator";

const Stack = createStackNavigator();

export function StackNavigator() {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Home"
        component={DrawerNavigator}
        options={{ headerShown: false }}
      />
      <Stack.Screen
        name="PaceUserDetail"
        component={PaceUserDetailScreen}
        options={{
          title: "Runner",
        }}
      />
      <Stack.Screen
        name="HighlightStepDetail"
        component={HighlightStepDetailScreen}
        options={{
          title: "Highlight",
        }}
      />
      <Stack.Screen name="Privacy" component={PrivacyScreen} />
      <Stack.Screen name="Share" component={ShareScreen} />
    </Stack.Navigator>
  );
}
