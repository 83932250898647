import { useCallback } from "react";
import Dropzone from "react-dropzone";

type Props = {
  onFileRead: (file: string) => void;
};

export function FileUploader({ onFileRead }: Props) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.warn("file reading was aborted");
      reader.onerror = () => console.error("file reading has failed");
      reader.onload = () => {
        // Do whatever you want with the file contents
        const fileString = reader.result;
        console.log(fileString);

        onFileRead(fileString as string);
      };
      reader.readAsText(file);
    });
  }, []);

  return (
    <Dropzone onDrop={onDrop}>
      {({ getRootProps, getInputProps }) => (
        <section>
          <div {...getRootProps()}>
            {/* TODO: Styling + more generic placeholder + file type/extension verification */}
            <input {...getInputProps()} />
            <p>Sleep hier een GPX-bestand heen</p>
          </div>
        </section>
      )}
    </Dropzone>
  );
}
