import { Link } from "@react-navigation/native";
import { Text, View } from "react-native";
import { HALF_MARATHON, KM, MARATHON } from "../lib/constants";
import { formatDistance } from "../lib/distance";
import { GlobalStyles } from "../lib/styles";
import { RouteStep } from "../types/common";

type Props = {
  step: RouteStep;
};

export function LegendStep({ step }: Props) {
  const { distance } = step;
  if (!distance) {
    return;
  }

  const title = step.type === "highlight" ? step.name : undefined;

  const isHighlightedStep =
    distance % (5 * KM) === 0 ||
    distance === HALF_MARATHON ||
    distance === MARATHON ||
    !!title;

  return (
    <View style={GlobalStyles.columnRow}>
      <Link
        to={step.type === "highlight" ? `/highlights/${step.id}` : ""}
        disabled={step.type !== "highlight"}
      >
        <Text style={[isHighlightedStep ? GlobalStyles.textBold : undefined]}>
          {title ? title + " " : ""}
          {formatDistance(distance)}
        </Text>
      </Link>
    </View>
  );
}
