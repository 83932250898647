import { LinkingOptions, NavigationContainer } from "@react-navigation/native";
import * as Linking from "expo-linking";
import { Text } from "react-native";
import { StackNavigator } from "./navigators/StackNavigator";
import { HighlightStepsContextProvider } from "./state/HighlightStepsState";
import { PaceUsersContextProvider } from "./state/PaceUsersState";
import { RaceContextProvider } from "./state/RaceState";

const prefix = Linking.createURL("/");

const linking: LinkingOptions<{}> = {
  prefixes: [prefix],
  config: {
    screens: {
      Home: {
        screens: {
          Overview: "overview",
          PaceUsers: "runners",
          HighlightSteps: "highlights",
          Map: "map",
          GPX: "gpx",
        },
      },
      PaceUserDetail: "runners/:id",
      HighlightStepDetail: "highlights/:id",
      Privacy: "privacy",
      Share: "share"
    },
  },
};

export default function App() {
  return (
    <RaceContextProvider>
      <HighlightStepsContextProvider>
        <PaceUsersContextProvider>
          <NavigationContainer
            linking={linking}
            fallback={<Text>Loading...</Text>}
          >
            <StackNavigator />
          </NavigationContainer>
        </PaceUsersContextProvider>
      </HighlightStepsContextProvider>
    </RaceContextProvider>
  );
}
