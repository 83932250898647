import Clipboard from "@react-native-community/clipboard";
import { useNavigation, useNavigationState } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { useEffect } from "react";
import { Button, Platform, Text, View } from "react-native";
import { getSharedRaceState, storeSharedRaceState } from "../lib/firebase.web";
import { GlobalStyles } from "../lib/styles";
import { useHighlightSteps } from "../state/HighlightStepsState";
import { usePaceUsers } from "../state/PaceUsersState";
import { useRace } from "../state/RaceState";

export function ShareScreen() {
  const { race, updateRace } = useRace();
  const { highlightSteps, updateHighlightSteps } = useHighlightSteps();
  const { paceUsers, updatePaceUsers } = usePaceUsers();
  const shareData = { race, highlightSteps, paceUsers };
  const sharedRaceStateId = useNavigationState((state) =>
    state && state.routes.length
      ? state.routes[0].params
        ? (state.routes[0].params as any).share
        : undefined
      : undefined
  );

  const navigation = useNavigation();

  useEffect(() => {
    if (sharedRaceStateId) {
      getAndSetSharedState(sharedRaceStateId).catch(console.error);
    }
  }, [sharedRaceStateId]);

  const getAndSetSharedState = async (raceId: string) => {
    const raceState = await getSharedRaceState(raceId);
    console.log(raceState);

    updateRace(raceState.race);
    updateHighlightSteps(raceState.highlightSteps);
    updatePaceUsers(raceState.paceUsers);

    navigation.navigate("Home");
  };

  const storeSharedState = async () => {
    console.log("Starting share...");
    try {
      const raceId = await storeSharedRaceState(shareData);

      // TODO: Get base url and append race id to ?share= url
      const shareUrl = `${raceId}`;

      if (Platform.OS === "web") {
        navigator.clipboard.writeText(shareUrl);
      } else {
        Clipboard.setString(shareUrl);
      }
    } catch (error) {
      console.error("Failed storing shared state", error);
    }
  };

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <Text style={{ margin: "auto", maxWidth: "100%" }}>
        {JSON.stringify(shareData)}
      </Text>

      <Button onPress={storeSharedState} title="Share this race" />
    </View>
  );
}
