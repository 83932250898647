import { StatusBar } from "expo-status-bar";
import { ScrollView, StyleSheet, Text, View } from "react-native";
import { LegendColumn } from "../components/LegendColumn";
import { PaceUserColumn } from "../components/PaceUserColumn";
import { GlobalStyles } from "../lib/styles";
import { useHighlightSteps } from "../state/HighlightStepsState";
import { usePaceUsers } from "../state/PaceUsersState";
import { useRace } from "../state/RaceState";
import { Link } from "@react-navigation/native";

export function OverviewScreen() {
  const { paceUsers } = usePaceUsers();
  const { highlightSteps } = useHighlightSteps();
  const {
    race: { distance: raceDistance },
  } = useRace();

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <ScrollView contentContainerStyle={styles.scrollContainer}>
        {paceUsers.length ? (
          <>
            <LegendColumn distance={raceDistance} extraSteps={highlightSteps} />
            <ScrollView horizontal={true}>
              {paceUsers.map((user) => (
                <PaceUserColumn
                  key={`user-column-${user.id}`}
                  user={user}
                  distance={raceDistance}
                  extraSteps={highlightSteps}
                />
              ))}
            </ScrollView>
          </>
        ) : (
          <Text>
            Add some <Link to={"/runners"}>runners</Link> to track first
          </Text>
        )}
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
