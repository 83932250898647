import { DistanceStep, HighlightStep, RouteStep } from "../types/common";
import { arrayRange } from "./array";
import { MARATHON, HALF_MARATHON, KM } from "./constants";

function getDistanceSteps(raceDistance: number): number[] {
  const distanceSteps = new Set([
    ...arrayRange(0, raceDistance, KM),
    raceDistance,
  ]);
  if (raceDistance === MARATHON) {
    distanceSteps.add(HALF_MARATHON);
  }

  return [...distanceSteps];
}

export function getSortedSteps(
  raceDistance: number,
  extraSteps: HighlightStep[]
): RouteStep[] {
  const distanceSteps = getDistanceSteps(raceDistance);
  const steps: RouteStep[] = [
    ...distanceSteps
      .filter(
        (distance) => !extraSteps.find((step) => step.distance === distance)
      )
      .map((distance) => ({ type: "distance", distance } as DistanceStep)),
    ...extraSteps,
  ]
    .filter((step) => step.distance <= raceDistance)
    .sort((a, b) => a.distance - b.distance);

  return steps;
}
