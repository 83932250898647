import { Location } from "../types/common";

const polyline = require("@mapbox/polyline");

export function decodePolyline(polylineString: string): Location[] {
  return polyline
    .decode(polylineString)
    .map((l: any) => ({ latitude: l[0], longitude: l[1] } as Location));
}

export function encodePolyline(locations: Location[]): string {
  return polyline.encode(locations.map((l) => [l.latitude, l.longitude]), 5);
}

type LatLonBounds = {
  north: number;
  south: number;
  west: number;
  east: number;
};

export function getBoundsFromLatLons(coordinates: Location[]): LatLonBounds {
  const lats = coordinates.map((p) => p.latitude);
  const lons = coordinates.map((p) => p.longitude);

  const bounds = {
    south: Math.min(...lats),
    west: Math.min(...lons),
    north: Math.max(...lats),
    east: Math.max(...lons),
  };
  return bounds;
}

export type Degrees = number;
export type Radians = number;

export function degreesToRadians(degrees: Degrees): Radians {
  return (degrees * Math.PI) / 180;
}

export function radiansToDegrees(radians: Radians): Degrees {
  return (radians * 180) / Math.PI;
}

/**
 * Distance is calculated using the haversine method.
 * Source: http://www.movable-type.co.uk/scripts/latlong.html
 */
export function getHaversineDistance(from: Location, to: Location): number {
  const R = 6371e3; // metres
  const lat1_in_radians = degreesToRadians(from.latitude);
  const lat2_in_radians = degreesToRadians(to.latitude);
  const delta_lat_in_radians = degreesToRadians(to.latitude - from.latitude);
  const delta_lon_in_radians = degreesToRadians(to.longitude - from.longitude);

  const a =
    Math.sin(delta_lat_in_radians / 2) * Math.sin(delta_lat_in_radians / 2) +
    Math.cos(lat1_in_radians) *
      Math.cos(lat2_in_radians) *
      Math.sin(delta_lon_in_radians / 2) *
      Math.sin(delta_lon_in_radians / 2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

  const d = R * c;
  return d;
}

export function calculatePolylineLength(
  polyline: string,
  untilLocation?: Location
) {
  const coordinates = decodePolyline(polyline);
  let totalDistance = 0;
  for (
    let i = 0;
    i < coordinates.length - 1 &&
    !locationsAreEqual(coordinates[i + 1], untilLocation);
    i++
  ) {
    totalDistance += getHaversineDistance(coordinates[i], coordinates[i + 1]);
  }

  return totalDistance;
}

export function getLocationForDistanceOnRoute(
  polyline: string,
  distanceOnRoute: number
): Location {
  const coordinates = decodePolyline(polyline);
  let totalDistance = 0;
  let i = 0;
  while (totalDistance < distanceOnRoute) {
    totalDistance += getHaversineDistance(coordinates[i], coordinates[i + 1]);
    i++;
  }

  return coordinates[i];
}

type LatLng = { lat: number; lng: number };
export function locationToLatLng(loc: Location): LatLng {
  return { lat: loc.latitude, lng: loc.longitude };
}

function locationsAreEqual(a: Location, b: Location | undefined) {
  return a.latitude === b?.latitude && a.longitude === b?.longitude;
}
