import { HighlightStep, PaceUser } from "../types/common";
import { formatDistance } from "./distance";
import { formatPace, getUserTimePerKilometer } from "./time-functions";

export function formatHighlightStepTitle(step: HighlightStep) {
  return `${step.name} (${formatDistance(step.distance)})`;
}

export function formatPaceUserTitle(user: PaceUser) {
  return `${user.name} (${formatPace(getUserTimePerKilometer(user))}/km)`;
}
