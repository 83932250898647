import { useEffect, useRef } from "react";
import {
  GoogleMap,
  Polyline as GooglePolyline,
  Marker as GoogleMarker,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { MAP_LINE_COLOR, MAP_LINE_WIDTH } from "../../lib/constants";
import {
  decodePolyline,
  getBoundsFromLatLons,
  locationToLatLng,
} from "../../lib/polyline";
import { MapViewProps, MarkerProps } from "./MapView";

const WebMapComponent = withScriptjs(
  withGoogleMap(({ polyline, children, ...props }: MapViewProps) => {
    const mapRef = useRef<GoogleMap>();
    useEffect(() => {
      if (!polyline) {
        return;
      }
      mapRef.current.fitBounds(getBoundsFromLatLons(decodePolyline(polyline)));
    }, [polyline]);

    return (
      <GoogleMap
        {...props}
        defaultZoom={3}
        defaultCenter={{ lat: 0, lng: 0 }}
        ref={mapRef}
      >
        {!!polyline && (
          <GooglePolyline
            path={decodePolyline(polyline).map(locationToLatLng)}
            options={{
              strokeColor: MAP_LINE_COLOR,
              strokeWeight: MAP_LINE_WIDTH,
            }}
          />
        )}
        {children}
      </GoogleMap>
    );
  })
);

export function MapView(props: MapViewProps) {
  return (
    <WebMapComponent
      {...props}
      googleMapURL="https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=AIzaSyCu799vhKfU45vzf3TBIJo6OqRKl_YfkvQ"
      loadingElement={<div style={{ height: `100%` }} />}
      containerElement={<div style={{ height: `100%`, width: "100%" }} />}
      mapElement={<div style={{ height: `100%`, width: "100%" }} />}
    />
  );
}

export function Marker(props: MarkerProps) {
  return (
    <GoogleMarker {...props} position={locationToLatLng(props.coordinate)} />
  );
}
