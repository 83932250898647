import { useRef, useState } from "react";
import { Button, InteractionManager, TextInput, View } from "react-native";
import { Colors, GlobalStyles } from "../lib/styles";
import { generateRandomId } from "../lib/uuid";
import { HighlightStep } from "../types/common";

type Props = {
  onAddHighlightStep: (step: HighlightStep) => void;
};

export function AddHighlightStepForm({ onAddHighlightStep }: Props) {
  const nameRef = useRef<TextInput>();
  const notesRef = useRef<TextInput>();
  const distanceRef = useRef<TextInput>();

  const [name, setName] = useState<string>();
  const [notes, setNotes] = useState<string>();
  const [distance, setDistance] = useState<number>();

  const addHighlightStep = () => {
    if (!name || !distance) {
      alert("Please fill in the details");
      return;
    }

    onAddHighlightStep({
      type: "highlight",
      id: generateRandomId(),
      name,
      distance,
      notes,
    });

    InteractionManager.runAfterInteractions(() => {
      nameRef.current.focus();
      nameRef.current.clear();
      notesRef.current.clear();
      distanceRef.current.clear();
      setName(undefined);
      setNotes(undefined);
      setDistance(undefined);
    });
  };

  const focusNotes = () => notesRef.current.focus();
  const focusDistance = () => distanceRef.current.focus();

  return (
    <View style={GlobalStyles.form}>
      <TextInput
        ref={nameRef}
        style={GlobalStyles.textInput}
        placeholder="Name"
        placeholderTextColor={Colors.GREY}
        onChangeText={setName}
        onSubmitEditing={focusNotes}
      />
      <TextInput
        ref={notesRef}
        style={GlobalStyles.textInput}
        placeholder="Notes (optional)"
        placeholderTextColor={Colors.GREY}
        onChangeText={setNotes}
        onSubmitEditing={focusDistance}
      />
      <TextInput
        ref={distanceRef}
        style={GlobalStyles.textInput}
        placeholder="Distance (in meters)"
        placeholderTextColor={Colors.GREY}
        onChangeText={(text) => setDistance(parseInt(text))}
        keyboardType="numeric"
        onSubmitEditing={addHighlightStep}
      />
      <Button title="Add" onPress={addHighlightStep} />
    </View>
  );
}
