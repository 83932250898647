import AsyncStorage from "@react-native-async-storage/async-storage";
import { createContext, useContext, useEffect, useState } from "react";
import { getUserTimePerKilometer } from "../lib/time-functions";
import { PaceUser } from "../types/common";

const defaultValue: PaceUser[] = [];

const PaceUsersContext = createContext({
  paceUsers: defaultValue,
  addPaceUser: (user: PaceUser) =>
    console.error("addPaceUser default method", { user }),
  removePaceUser: (user: PaceUser) =>
    console.error("removePaceUser default method", { user }),
  updatePaceUsers: (users: PaceUser[]) =>
    console.error("updatePaceUsers default method", { users }),
});

export const usePaceUsers = () => useContext(PaceUsersContext);

export function PaceUsersContextProvider({ children }) {
  const STORAGE_KEY = "MARATHON::PACE_USERS";
  const [paceUsers, setPaceUsers] = useState(defaultValue);

  const setAndSortPaceUsers = (users: PaceUser[]) => {
    setPaceUsers(
      users.sort(
        (a, b) => getUserTimePerKilometer(a) - getUserTimePerKilometer(b)
      )
    );
  };

  const addPaceUser = (user: PaceUser) => {
    console.log("addPaceUser", user);
    setAndSortPaceUsers([...paceUsers, user]);
  };

  const removePaceUser = (user: PaceUser) => {
    console.log("removePaceUser", user);
    setPaceUsers(paceUsers.filter((u) => u.id !== user.id));
  };

  const updatePaceUsers = (users: PaceUser[]) => {
    console.log("updatePaceUsers", users);
    setPaceUsers([...users]);
  };

  useEffect(() => {
    AsyncStorage.getItem(STORAGE_KEY).then((value) => {
      if (value) {
        setAndSortPaceUsers(JSON.parse(value));
      }
    });
  }, []);

  useEffect(() => {
    if (paceUsers !== defaultValue) {
      AsyncStorage.setItem(STORAGE_KEY, JSON.stringify(paceUsers));
    }
  }, [paceUsers]);

  return (
    <PaceUsersContext.Provider
      value={{ paceUsers, addPaceUser, removePaceUser, updatePaceUsers }}
    >
      {children}
    </PaceUsersContext.Provider>
  );
}
