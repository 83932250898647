import { DrawerScreenProps } from "@react-navigation/drawer";
import { StatusBar } from "expo-status-bar";
import { useEffect } from "react";
import { ScrollView, StyleSheet, View } from "react-native";
import { LegendColumn } from "../components/LegendColumn";
import { PaceUserColumn } from "../components/PaceUserColumn";
import { GlobalStyles } from "../lib/styles";
import { formatPaceUserTitle } from "../lib/titles";
import { useHighlightSteps } from "../state/HighlightStepsState";
import { usePaceUsers } from "../state/PaceUsersState";
import { useRace } from "../state/RaceState";

type Props = DrawerScreenProps<any>;

export function PaceUserDetailScreen({ route, navigation }: Props) {
  const { paceUsers } = usePaceUsers();
  const { highlightSteps } = useHighlightSteps();
  const {
    race: { distance: raceDistance },
  } = useRace();
  const user = paceUsers.find((u) => u.id === route.params.id);

  useEffect(() => {
    if (user?.name) {
      navigation.setOptions({ headerTitle: formatPaceUserTitle(user) });
    }
  }, [user]);

  if (!user) {
    return;
  }

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <ScrollView contentContainerStyle={styles.scrollContainer}>
        <LegendColumn distance={raceDistance} extraSteps={highlightSteps} />
        <PaceUserColumn
          key={`user-column-${user.id}`}
          user={user}
          distance={raceDistance}
          extraSteps={highlightSteps}
        />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  scrollContainer: {
    flexDirection: "row",
    justifyContent: "flex-end",
  },
});
