import { StyleSheet } from "react-native";

export const MARGIN_SMALL = 8;
export const MARGIN_MEDIUM = MARGIN_SMALL * 2;
export const MARGIN_LARGE = MARGIN_MEDIUM * 2;

export const GlobalStyles = StyleSheet.create({
  screenContainer: {
    flex: 1,
    backgroundColor: "#fff",
    alignItems: "center",
    justifyContent: "center",
  },
  column: {
    paddingHorizontal: MARGIN_MEDIUM,
    alignItems: "center",
    justifyContent: "flex-end",
    marginBottom: MARGIN_MEDIUM,
  },
  columnRow: {
    flexDirection: "row",
    justifyContent: "space-between",
  },
  form: {
    margin: MARGIN_MEDIUM,
  },
  textInput: {
    margin: MARGIN_SMALL,
  },
  list: {
    width: "100%",
  },
  listContainer: {
    paddingHorizontal: MARGIN_MEDIUM,
    paddingBottom: MARGIN_SMALL,
  },
  listItem: {
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: MARGIN_SMALL,
  },
  textBold: {
    fontWeight: "bold",
  },
  textCenter: {
    textAlign: "center",
  },
});

export const Colors = {
  GREY: "#727171",
};
