import { Link } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { Button, FlatList, Text, View } from "react-native";
import { AddUserForm } from "../components/AddUserForm";
import { GlobalStyles } from "../lib/styles";
import { formatPaceUserTitle } from "../lib/titles";
import { usePaceUsers } from "../state/PaceUsersState";

export function PaceUsersScreen() {
  const { paceUsers, addPaceUser, removePaceUser } = usePaceUsers();

  return (
    <View style={GlobalStyles.screenContainer}>
      <StatusBar style="auto" />

      <AddUserForm onAddUser={addPaceUser} />

      <FlatList
        data={paceUsers}
        renderItem={({ item: user }) => (
          <View style={GlobalStyles.listItem}>
            <Link to={`/runners/${user.id}`}>
              <Text>{formatPaceUserTitle(user)}</Text>
            </Link>
            {/* <Button title="Edit" /> */}
            <Button title="Remove" onPress={() => removePaceUser(user)} />
          </View>
        )}
        keyExtractor={(item) => item.id}
        style={GlobalStyles.list}
        contentContainerStyle={GlobalStyles.listContainer}
      />
    </View>
  );
}
