import { Link } from "@react-navigation/native";
import { useState } from "react";
import { Text, TextInput, View } from "react-native";
import { HOUR, MINUTE } from "../lib/constants";
import { getSortedSteps } from "../lib/steps";
import { GlobalStyles, MARGIN_MEDIUM } from "../lib/styles";
import {
  formatClockTime,
  getUserTimePerKilometer,
  parsePace
} from "../lib/time-functions";
import { formatPaceUserTitle } from "../lib/titles";
import { useRace } from "../state/RaceState";
import { HighlightStep, PaceUser } from "../types/common";
import { PaceUserStep } from "./PaceUserStep";

type Props = {
  user: PaceUser;
  distance: number;
  extraSteps?: HighlightStep[];
};

export function PaceUserColumn({ user, distance, extraSteps }: Props) {
  const kilometerTime = getUserTimePerKilometer(user);
  const steps = getSortedSteps(distance, extraSteps);
  const { race } = useRace();

  const [startTimeString, setStartTime] = useState<string>(
    formatClockTime(race.startTime) || "10:00"
  );
  const startTime = (parsePace(startTimeString) * HOUR) / MINUTE;

  return (
    <View style={GlobalStyles.column}>
      <Link to={`/runners/${user.id}`}>
        <Text style={GlobalStyles.textBold}>{formatPaceUserTitle(user)}</Text>
      </Link>

      <View
        style={{
          flexDirection: "row",
          alignItems: "flex-end",
          flex: 1,
        }}
      >
        <Text style={{ marginRight: MARGIN_MEDIUM }}>Start time</Text>
        <TextInput
          style={{ maxWidth: 50 }}
          value={startTimeString}
          placeholder="Start time"
          onChangeText={setStartTime}
          keyboardType="numeric"
        />
      </View>

      <View>
        {steps.map((step) => (
          <PaceUserStep
            key={`${user.id}-${step.distance}`}
            kilometerTime={kilometerTime}
            distance={step.distance}
            startTime={startTime}
          />
        ))}
      </View>
    </View>
  );
}
